import React from 'react'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'

import styled from 'styled-components'

const Main = styled.div`
  display: grid;
  grid-area: Main;
  margin: 0 auto;
  min-height: 100%;
	grid-template-columns: 100%;
  justify-content: center;
` 

export interface PageProps {
  pageContext: {
    modules: []
    slug: string
    main: {
      title: string
    },
    meta: {}
  }
  path?: string
  preview?: boolean
}

const Page = ({
  path,
  pageContext,
  preview = false
}: PageProps) => {
  const {
    modules,
    slug,
    meta
  } = pageContext

  const url = slug === 'home' ? '' : path
  return (
    <Main>
      {preview && (
        <div className='bcblue ac cw x p1'>This is a Preview</div>
      )}
      <SEO metaInfo={meta} pagePath={url} />
      
        {RenderModules(modules)}
    </Main>
  )
}

export default Page